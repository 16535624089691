import * as S from './Footer.styled'
import * as G from '../../styles/global.styles'
import { IconEnums } from '../base'
import { Fragment, useContext } from 'react'
import {
  Link,
  Iconlink,
  MenuAPIProps,
  SiteSettingProps
} from '../../services/api/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import { sampleFooterProps } from '../../services/mock'
import { MenuContext } from '../../services/context/MenuContext'
import { SiteContext } from '../../services/context/SiteContext'
import { getCountryFromCode } from '../../services/api/helpers'

export interface ISocial {
  label: string
  socialLinks: Iconlink[]
  newSocialLinks: Iconlink[]
}

export interface FooterProps {
  logo: string
  logoNew: string
  partner: string
  iconFooter: string
  partnerLink: string
  copyright: string
  links: Link[]
  social: {
    label: string
    socialLinks: Iconlink[]
    newSocialLinks: Iconlink[]
  }
  siteLinks: Link[]
  newLayout?: boolean
}
export const Footer = () => {
  const { siteSettings } = useContext(SiteContext)

  return (
    <MenuContext.Consumer>
      {({ flatMenuData }) => {
        if (flatMenuData) {
          const {
            logo,
            partner,
            partnerLink,
            copyright,
            links,
            social,
            siteLinks
          } = flatMenuData

          return (
            <G.Section bgColor='plus_header_grey'>
              <G.Container contentSize='lg'>
                <S.FooterContainer>
                  <a href='/'>
                    <S.Logo
                      src={logo}
                      width='120'
                      height='120'
                      alt={copyright}
                    />
                  </a>
                  <S.InfoGroup>
                    <S.Copyright>{copyright}</S.Copyright>
                    {siteSettings?.show_address_in_footer ? (
                      <S.Address>
                        {siteSettings.address_street}{' '}
                        {siteSettings.address_suburb}{' '}
                        {siteSettings.address_state_code}{' '}
                        {getCountryFromCode(siteSettings.address_country)}{' '}
                        {siteSettings.address_postcode}
                      </S.Address>
                    ) : null}
                    <S.LinkGroup>
                      {links &&
                        links.map((link, idx) => (
                          <Fragment key={idx}>
                            <G.Link href={link.url} target={link.target}>
                              {link.label == 'Login' && (
                                <FontAwesomeIcon
                                  className='mr-2'
                                  icon={faKey}
                                />
                              )}
                              {link.label}
                            </G.Link>
                          </Fragment>
                        ))}
                      {siteLinks &&
                        siteLinks.map((link, ix) => (
                            <Fragment key={ix}>
                            {link.label != 'Plus fitness franchising' && (
                              <G.Link href={link.url} target={link.target}>
                                {link.label}
                              </G.Link>
                            )}
                          </Fragment>
                        ))}
                    </S.LinkGroup>
                  </S.InfoGroup>
                  <S.SocialGroup>
                    <S.SocialLabel>{social.label}</S.SocialLabel>
                    <S.SocialIcons>
                      {social.socialLinks &&
                        social.socialLinks.map(
                          (link, idx) =>
                            link.url && (
                              <G.Link key={idx} href={link.url}>
                                <S.Icon name={link.icon} />
                              </G.Link>
                            )
                        )}
                    </S.SocialIcons>
                  </S.SocialGroup>
                  {siteSettings?.show_viva_logo ? (
                    <S.PartnerGroup>
                      <p>A division of</p>
                      <a href={partnerLink} target='_blank'>
                        <S.Partner
                          src={partner}
                          width='120'
                          height='120'
                          alt=''
                        />
                      </a>
                    </S.PartnerGroup>
                  ) : null}
                </S.FooterContainer>
              </G.Container>
            </G.Section>
          )
        }
      }}
    </MenuContext.Consumer>
  )
}

Footer.ApiBlockToProps = (
  menuItems?: MenuAPIProps,
  siteSettings?: SiteSettingProps,
  siteLinks?: Link[]
): FooterProps => {
  let links = new Array<Link>()

  if (menuItems && menuItems.items) {
    const { items } = menuItems
    links = items[0].menu_items?.map((link: any) => {
      return {
        ...link,
        label: link.link_text
      }
    })
  }

  return {
    links: links,
    siteLinks: siteLinks || [],
    social: {
      label: 'Follow Us',
      socialLinks: [
        {
          icon: IconEnums['icon-whatsapp'],
          url: siteSettings?.whatsapp || ''
        },
        {
          icon: IconEnums['facebook-filled'],
          url: siteSettings?.facebook || ''
        },
        {
          icon: IconEnums['threads-filled'],
          url: siteSettings?.threads || ''
        },
        {
          icon: IconEnums['twitter-filled'],
          url: siteSettings?.twitter || ''
        },
        {
          icon: IconEnums['linkedin-filled'],
          url: siteSettings?.linkedin || ''
        },
        {
          icon: IconEnums['instagram-filled'],
          url: siteSettings?.instagram || ''
        },
        {
          icon: IconEnums['youtube-filled'],
          url: siteSettings?.youtube || ''
        }
      ],
      newSocialLinks: [
        {
          icon: IconEnums['icon-whatsapp'],
          url: siteSettings?.whatsapp || ''
        },
        {
          icon: IconEnums['icon-fb2'],
          url: siteSettings?.facebook || ''
        },
        {
          icon: IconEnums['icon-instagram2'],
          url: siteSettings?.instagram || ''
        },
        {
          icon: IconEnums['threads-filled'],
          url: siteSettings?.threads || ''
        },
        {
          icon: IconEnums['icon-youtube2'],
          url: siteSettings?.youtube || ''
        },
        {
          icon: IconEnums['twitter-filled'],
          url: siteSettings?.twitter || ''
        },
        {
          icon: IconEnums['linkedin-filled'],
          url: siteSettings?.linkedin || ''
        }
      ]
    },

    logo: sampleFooterProps.logo,
    partner: sampleFooterProps.partner,
    partnerLink: sampleFooterProps.partnerLink,
    copyright: sampleFooterProps.copyright,
    logoNew: sampleFooterProps.logoNew,
    iconFooter: sampleFooterProps.iconFooter
  }
}

export default Footer
